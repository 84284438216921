import { useState, useRef } from 'react'
import { Dialog, Pane, majorScale, Heading, Paragraph, Button, ManualIcon, ResetIcon } from 'evergreen-ui'
import { elementStylesheet as stylesheet } from '../graphoptions'
import { Element, fetchElementBySlug } from '../api'
import CytoscapeComponent from 'react-cytoscapejs'

interface TreeViewModalProps {
    element: Element
    isShown: boolean
    onClose: () => void
}

const TreeViewModal = ({ isShown, onClose, element }: TreeViewModalProps) => {
    const [highlightedElement, setHighlightedElement] = useState<Element>(element)

    let cyRef = useRef<cytoscape.Core>()

    const handleResetZoom = () => {
        const cy = cyRef.current
        cy?.animate({
            fit: {
                eles: cy?.elements(),
                padding: 20
            }
        }, {
            duration: 500
        })
    }

    const setupGraph = (cy: cytoscape.Core) => {
        cyRef.current = cy

        cy.on('tap', 'node', async (e: cytoscape.EventObject) => {
            var sel = e.target;
            const element = await fetchElementBySlug(sel.data()["label"])
            setHighlightedElement(element)

            cy.elements().removeClass("treeViewHighlighted")
            sel.addClass("treeViewHighlighted")

            const kind = sel.data()["kind"]
            let nodes, edges
            if (kind === "used_in") {
                nodes = element.used_in.map((e) => ({ data: { id: `${e.slug}`, book_number: e.book, label: e.slug, kind: "used_in" } }))
                edges = element.used_in.filter((e) => e.slug !== element.slug).map((e) => ({
                    data: {
                        id: `${element.slug}${e.slug}`,
                        source: element.slug,
                        target: e.slug
                    }
                }))
            } else {
                nodes = element.depends_on.map((e) => ({ data: { id: `${e.slug}`, book_number: e.book, label: e.slug, kind: "depends_on" } }))
                edges = element.depends_on.filter((e) => e.slug !== element.slug).map((e) => ({
                    data: {
                        id: `${e.slug}${element.slug}`,
                        source: e.slug,
                        target: element.slug
                    }
                }))
            }

            cy.add(CytoscapeComponent.normalizeElements({ nodes, edges }))

            //@ts-ignore
            cy.layout({ name: "dagre", fit: true, animate: true, animationEasing: 'ease-in-out-sine', animationDuration: 500 }).run()

            // This needs to be called due to buggy mouse position behavior
            // See https://stackoverflow.com/questions/23461322/cytoscape-js-wrong-mouse-pointer-position-after-container-change
            cy.resize()

        })
    }

    const nodes = [
        { data: { id: element.slug, label: element.slug, book_number: element.book } },
        ...element.used_in.map(e => ({ data: { id: e.slug, label: e.slug, book_number: e.book, kind: "used_in" } })),
        ...element.depends_on.map(e => ({ data: { id: e.slug, label: e.slug, book_number: e.book, kind: "depends_on" } })),
    ];

    const edges = [
        ...element.used_in.map(e => ({ data: { id: `${element.slug}${e.slug}`, source: element.slug, target: e.slug } })),
        ...element.depends_on.map(e => ({ data: { id: `${e.slug}${element.slug}`, source: e.slug, target: element.slug } })),
    ]

    return (
        <Dialog
            isShown={isShown}
            onCloseComplete={() => onClose()}
            hasFooter={false}
            hasHeader={false}
            width={"95%"}
        >
            <Pane padding={majorScale(2)} margin={majorScale(2)} border>
                <Heading size={700} marginBottom={majorScale(1)}>{highlightedElement.slug}</Heading>
                <Paragraph size={500}>{highlightedElement.english}</Paragraph>
                <Button onClick={() => { window.open(`/#/element/${highlightedElement.slug}`, '_default') }} size={"small"} iconBefore={ManualIcon}>Go to element</Button>
            </Pane>
            <Pane>
                <Pane textAlign="right">
                    <Button onClick={handleResetZoom} iconBefore={ResetIcon} size="small">Reset Zoom</Button>
                </Pane>
                <Pane height={300}>
                    <CytoscapeComponent
                        cy={setupGraph}
                        stylesheet={stylesheet}
                        elements={CytoscapeComponent.normalizeElements({ nodes, edges })}
                        layout={{
                            name: "dagre"
                        }}
                        style={{ width: '100%', height: '100%' }}
                        minZoom={0.1}
                        maxZoom={1}
                    />
                </Pane>

            </Pane>
        </Dialog >
    )
}

export default TreeViewModal

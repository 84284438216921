import { Pane, Paragraph, Heading, majorScale } from 'evergreen-ui'

export default () => {
    return (
        <Pane>
            <Heading size={800}>Instructions</Heading>
            <Paragraph>
                At the top of the window, after “Home” and “Instructions” there are two main menus: “Text and Tree View” and “Graph Visualizations”, and "GPT" detailed below.
            </Paragraph>
            <Paragraph>
                The “search” box that appears in the top right corner of the screen (also in all tabs opened by the two main menus) allows for both English and Latin text searches. This function shows all occurrences in the Ethics of the word searched for.
            </Paragraph>

            <Pane>
                <Heading>Text and "Family Tree" View</Heading>
                <Paragraph>
                    At the top of the window, a “quicknav” menu provides a quick way of navigating the  Ethics and visualizing the “family tree” of individual elements (axioms, definitions, propositions, etc.).
                </Paragraph>
                <Heading>Full Content</Heading>
                <Paragraph>
                    By clicking on any individual “element” (axiom, definition proposition), the full text, in English, will appear. To view the original Latin and Dutch, click anywhere on the English text. A new window will be shown with all translations. By double clicking on any Latin word, an additional window will open with the dictionary entry and syntactical information provided by the Perseus Project.
                </Paragraph>
                <Paragraph>
                    Above the text, there is a visualization of the immediate “Parent Elements” and “Child Elements” of that element, i.e. the elements that precede it and are cited in it, and the elements that come after the element and cite it, based on Spinoza’s own indications. The visualization can zoom in and out using the mouse scroll wheel. Elements can also be dragged. Clicking on any of these will open a box at the bottom of the window, which shows the main content of that element. Click on “Go to element page” to see the full content of that element.
                </Paragraph>
                <Heading>"Family Tree" View</Heading>
                <Paragraph>
                    To develop the family tree of an element, click on “Open Explorer”. The currently highlighted element is grey. The colors of the nodes correspond to which Part of the Ethics it belongs. Red for Part I. Orange for Part II. Magenta for Part III. Purple for Part IV. Blue for Part V.               
                </Paragraph>
                <Paragraph>
                    By clicking on any parent or child element, the own antecedents or posterity of that element will be loaded in the same window. In other words, the family tree will expand. Please note that only the parents or the children of that parent or child element that is clicked on will appear on the map, not the antecedents or posterity of the other parent or child elements that are on the same level.
                </Paragraph>
                <Paragraph>
                    One can repeat the same operation and expand even more the family tree either upwards or downwards, as far as it is possible to go in the genealogy of a certain line of references. For example, from 1p11, the genealogy line 1p21 – 2p11 – 5p38 – 5p42 will be progressively built. The window can be zoomed out as the tree expands. By clicking again on the initial element, one can go back to the original view.
                </Paragraph>
                <Paragraph>
                    Important warning: the elements displayed are only those related to the initial element of the tree (1p11 in our example). Therefore, a number of other connections may not be displayed. Thus, 2p11 will appear on the tree by clicking on 1p21, and 2p11 at this point will display only this connection to 1p21 as its parent. But 2p11 has in fact seven parent elements, and the other six are not being displayed. This is because the six other parent elements have no connection to 1p11, which is the element determining the mapping of the lineage. To visualize all the parents of 2p11, there are two solutions:
                    <ol>
                        <li>Start a new tree from 2p11 by hovering on it and choosing “View Tree”.</li>
                        <li>Use the full graph visualization (see below).</li>
                    </ol>
                </Paragraph>
                <Paragraph>
                    It is essential to use one of these two ways to check the complete genealogic lines of any element that appears in the process of expansion of a given family tree.
                </Paragraph>
            </Pane>

            <Pane>
                <Heading>Graph Visualizations</Heading>
                <Paragraph>This tab of the menu bar shows maps of the entire Ethics, displaying all of the elements and intertextual connections that Spinoza provides.</Paragraph>
                <Paragraph>Different layouts (grid, circle, etc.) can be chosen and toggled between. The different parts of the Ethics can be filtered (made to appear or disappear) by checking or unchecking the box next to each part listed on the side.</Paragraph>
                <Paragraph>To navigate these maps, use the mouse scroll to zoom in and out, click and drag any element on the ‘background’ (any area without an element or connection) to center any part.</Paragraph>
                <Paragraph>Hover the cursor over any element in order to highlight its connections. Click on the element to keep the connections highlighted while moving the cursor elsewhere, and click on the background to make the illumination disappear. Double click on the element to open its main content box. From that box, you can go to the element page with its full content and tree view.</Paragraph>
            </Pane>

            <Pane>
                <Heading>GPT</Heading>
                <Paragraph>This tab is an experimental feature. It uses ChatGPT to summarize the connections between propositions. This works by finding first finding a shortest path distance between two elements. Then, the text of these nodes are fed into ChatGPT as a Prompt and we ask ChatGPT to explain the connection between them</Paragraph>
                <Paragraph>ChatGPT is not always accurate. In fact, it is always in some degree inaccurate. This tool should only be used to help aid understanding, and not to be accepted as a true representation of the Ethics.</Paragraph>
            </Pane>

        </Pane>
    )
}

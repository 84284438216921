import { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Pane, Paragraph, Heading, majorScale } from 'evergreen-ui'
import { fetchElements, Element } from '../api'
import { useNavigate } from 'react-router-dom'
import { ViewportList } from 'react-viewport-list';
import SearchCard from '../components/SearchCard'
import Fuse from 'fuse.js'




export default () => {
    const [searchParams, _] = useSearchParams();
    const [results, setResults] = useState<Fuse.FuseResult<Element>[]>([])
    const query = searchParams.get("q")
    const navigate = useNavigate()

    const [fuse, setFuse] = useState<Fuse<Element> | null>(null)

    useEffect(() => {
        const f = async () => {
            const elements = await fetchElements()
            const fuseOptions = {
                keys: ['english', 'latin', 'dutch'],
                threshold: 0.0,
                ignoreLocation: true,
                includeMatches: true

            }
            setFuse(new Fuse(elements, fuseOptions))
        }
        f()
    }, [])

    useEffect(() => {
        if (fuse && query) {
            setResults(fuse.search(` ${query} `))
        }
    }, [query, fuse])

    const ref = useRef<HTMLDivElement | null>(
        null,
    );

    return (
        <Pane>
            <Pane ref={ref} className="scroll-container">
                {query && results.length === 0 && (
                    <Pane padding={majorScale(2)} textAlign="center">
                        <Heading>No results found for</Heading>
                        <Paragraph>{query}</Paragraph>
                    </Pane>
                )}
                <ViewportList
                    viewportRef={ref}
                    items={results}
                >
                    {(e) => (
                        <Pane background="white" border padding={majorScale(2)} margin={majorScale(2)} className="clickable" onClick={() => navigate(`/element/${e.item.slug}`)}>
                            <Heading>{e.item.slug}</Heading>
                            <Paragraph>{e.matches && e.matches[0].value}</Paragraph>
                        </Pane>
                    )}
                </ViewportList>
            </Pane>
        </Pane >
    )
}

export interface Element {
    type: string
    slug: string
    book: string
    english: string
    dutch: string
    latin: string

    children: Element[]
    parent: Element
    depends_on: Element[]
    used_in: Element[]

    // computed attributes
    typeNumber: number
}

let cachedElements: Element[]

const regexp = new RegExp('(?<book>(1|2|3DA|3|4|5)){1}(?<type>(P|A|D|L)){1}(?<type_num>[0-9]+)(?<subtype>.*)')

const _serializeElement = (elem: Element): Element => {
    const computed = {
        typeNumber: -1
    }
    const match = elem.slug.match(regexp)
    if (match) {
        //@ts-ignore
        computed["typeNumber"] = parseInt(match.groups["type_num"])
    }

    const result = {
        ...elem,
        children: elem.children.map(_serializeElement),
        depends_on: elem.depends_on.map(_serializeElement),
        used_in: elem.used_in.map(_serializeElement),
        ...computed
    }

    if (elem.parent) {
        result.parent = _serializeElement(elem.parent)
    }

    return result
}

export async function fetchElementBySlug(slug: string): Promise<Element> {
    const res = await fetch(`/elements/slug/${slug}`)
    const data = await res.json()
    return _serializeElement(data)
}

export async function fetchElements() {
    if (!cachedElements) {
        const res = await fetch("/elements/list")
        const data = await res.json()
        cachedElements = data.map(_serializeElement)
    }
    return cachedElements
}

export async function fetchSlugs() {
    const res = await fetch("/elements/slugs")
    return await res.json()
}

export async function fetchGraphData() {
    const res = await fetch("/elements/graph-data")
    const data = await res.json()
    return data
}

export async function fetchMapData() {
    const res = await fetch("/elements/map-data")
    const data = await res.json()
    return data
}

export async function fetchSourcesForNode(slug: string): Promise<{ nodes: string[] }> {
    const res = await fetch(`/elements/map/${slug}`)
    const data = await res.json()
    return data
}

export async function postGPTQuery(path: string[]) {
    const formData = new FormData()
    path.map(p => {
        formData.append("element_slugs[]", p)
    })
    //    formData.append("element_slugs[]", JSON.stringify(path))
    const res = await fetch("/elements/gpt", {
        method: "POST",
        body: formData
    })
    return await res.json()
}
